import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../pages-components/tic-tac-toe/tic-tac-toe.css";

import { ClassGame } from "../pages-components/tic-tac-toe/class-tic-tac-toe";
import { FunctionGame } from "../pages-components/tic-tac-toe/function-tic-tac-toe";

function Page() {
  return (
    <Layout>
      <SEO title="Tic-Tac-Toe"></SEO>
      <h1>Class Component Tic-Tac-Toe</h1>
      <ClassGame />
      <h1>Function Component Tic-Tac-Toe</h1>
      <FunctionGame />
    </Layout>
  );
}

export default Page;
