import React, { useState, useCallback } from "react";

export function FunctionGame() {
  return (
    <div className="game">
      <div className="game-board">
        <Board />
      </div>
      <div className="game-info">
        <div>{/* status */}</div>
        <ol>{/* TODO */}</ol>
      </div>
    </div>
  );
}

function Board() {
  // State
  const [squares, setSquares] = useState(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState(true);

  // const handleClick = (...) => {...};
  // const handleClick = useCallback((...) => {...}, []);

  // Callbacks (passing functions as props)
  const handleClick = useCallback(
    (i: number) => {
      if (calculateWinner(squares) || squares[i]) {
        return;
      }

      const newSquares = squares.slice();
      newSquares[i] = xIsNext ? "X" : "O";

      setSquares(newSquares);
      setXIsNext(!xIsNext);
    },
    [squares, xIsNext, setSquares, setXIsNext]
  );

  // Calculating derived state (comes from other things)
  const winner = calculateWinner(squares);
  let status;
  if (winner) {
    status = "Winner: " + winner;
  } else {
    status = "Next player: " + (xIsNext ? "X" : "O");
  }

  // JSX (rendering)
  return (
    <div>
      <div className="status">{status}</div>
      <div className="board-row">
        <Square value={squares[0]} onClick={() => handleClick(0)} />
        <Square value={squares[1]} onClick={() => handleClick(1)} />
        <Square value={squares[2]} onClick={() => handleClick(2)} />
      </div>
      <div className="board-row">
        <Square value={squares[3]} onClick={() => handleClick(3)} />
        <Square value={squares[4]} onClick={() => handleClick(4)} />
        <Square value={squares[5]} onClick={() => handleClick(5)} />
      </div>
      <div className="board-row">
        <Square value={squares[6]} onClick={() => handleClick(6)} />
        <Square value={squares[7]} onClick={() => handleClick(7)} />
        <Square value={squares[8]} onClick={() => handleClick(8)} />
      </div>
    </div>
  );
}
interface SquareProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  value: string;
}
// Props and object destructuring
function Square({ onClick, value }: SquareProps) {
  return (
    <button className="square" onClick={onClick}>
      {value} {/* label of the value */}
    </button>
  );
}

function calculateWinner(squares: string[][]) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
}
